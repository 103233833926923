// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "../js/popover.js"
import "../stylesheets/application"
import "trix"
import "@rails/actiontext"
//import Dropzone from "dropzone";
//import "dropzone/dist/dropzone.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'vue-select/dist/vue-select.css'; 

//require.context('../images', true)

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

/*
Dropzone.autoDiscover = false;
let myDropzone: Dropzone = new Dropzone("#comment_form");
myDropzone.on("addedfile", file => {
  console.log(`File added: ${file.name}`);
});
*/